// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-blog-starter-src-pages-404-js": () => import("./../../../node_modules/gatsby-theme-blog-starter/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-starter-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-blog-starter-src-templates-post-list-js": () => import("./../../../node_modules/gatsby-theme-blog-starter/src/templates/post-list.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-starter-src-templates-post-list-js" */),
  "component---node-modules-gatsby-theme-blog-starter-src-templates-tag-posts-js": () => import("./../../../node_modules/gatsby-theme-blog-starter/src/templates/tag-posts.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-starter-src-templates-tag-posts-js" */),
  "component---node-modules-gatsby-theme-blog-starter-src-templates-tags-page-js": () => import("./../../../node_modules/gatsby-theme-blog-starter/src/templates/tags-page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-starter-src-templates-tags-page-js" */),
  "component---posts-2018-08-04-irab-2018-08-04-irab-md": () => import("./../../../posts/2018-08-04-irab/2018-08-04-irab.md" /* webpackChunkName: "component---posts-2018-08-04-irab-2018-08-04-irab-md" */),
  "component---posts-2018-08-06-inna-and-sisters-2018-08-06-inna-and-sisters-md": () => import("./../../../posts/2018-08-06-Inna-and-sisters/2018-08-06-Inna-and-sisters.md" /* webpackChunkName: "component---posts-2018-08-06-inna-and-sisters-2018-08-06-inna-and-sisters-md" */),
  "component---posts-2018-08-11-six-ism-of-arabic-grammar-2018-08-11-six-ism-of-arabic-grammar-md": () => import("./../../../posts/2018-08-11-six-ism-of-arabic-grammar/2018-08-11-six-ism-of-arabic-grammar.md" /* webpackChunkName: "component---posts-2018-08-11-six-ism-of-arabic-grammar-2018-08-11-six-ism-of-arabic-grammar-md" */),
  "component---posts-2018-08-11-when-to-use-tanween-2018-08-11-when-to-use-tanween-md": () => import("./../../../posts/2018-08-11-when-to-use-tanween/2018-08-11-when-to-use-tanween.md" /* webpackChunkName: "component---posts-2018-08-11-when-to-use-tanween-2018-08-11-when-to-use-tanween-md" */),
  "component---posts-2018-08-12-manqoos-2018-08-12-manqoos-md": () => import("./../../../posts/2018-08-12-manqoos/2018-08-12-manqoos.md" /* webpackChunkName: "component---posts-2018-08-12-manqoos-2018-08-12-manqoos-md" */),
  "component---posts-2018-08-13-ism-maqsoor-2018-08-13-ism-maqsoor-md": () => import("./../../../posts/2018-08-13-ism-maqsoor/2018-08-13-ism-maqsoor.md" /* webpackChunkName: "component---posts-2018-08-13-ism-maqsoor-2018-08-13-ism-maqsoor-md" */),
  "component---posts-2018-08-16-kana-and-sisters-2018-08-16-kana-and-sisters-md": () => import("./../../../posts/2018-08-16-kana-and-sisters/2018-08-16-kana-and-sisters.md" /* webpackChunkName: "component---posts-2018-08-16-kana-and-sisters-2018-08-16-kana-and-sisters-md" */),
  "component---posts-2018-08-18-verbs-in-arabic-grammar-2018-08-18-verbs-in-arabic-grammar-md": () => import("./../../../posts/2018-08-18-verbs-in-arabic-grammar/2018-08-18-verbs-in-arabic-grammar.md" /* webpackChunkName: "component---posts-2018-08-18-verbs-in-arabic-grammar-2018-08-18-verbs-in-arabic-grammar-md" */),
  "component---posts-2018-08-30-arabic-exclamations-2018-08-30-arabic-exclamations-md": () => import("./../../../posts/2018-08-30-arabic-exclamations/2018-08-30-arabic-exclamations.md" /* webpackChunkName: "component---posts-2018-08-30-arabic-exclamations-2018-08-30-arabic-exclamations-md" */),
  "component---posts-2018-09-02-ma-nafiyah-2018-09-02-ma-nafiyah-md": () => import("./../../../posts/2018-09-02-ma-nafiyah/2018-09-02-ma-nafiyah.md" /* webpackChunkName: "component---posts-2018-09-02-ma-nafiyah-2018-09-02-ma-nafiyah-md" */),
  "component---posts-2018-09-06-harf-nida-2018-09-06-harf-nida-md": () => import("./../../../posts/2018-09-06-harf-nida/2018-09-06-harf-nida.md" /* webpackChunkName: "component---posts-2018-09-06-harf-nida-2018-09-06-harf-nida-md" */),
  "component---posts-2018-09-14-arabic-grammar-2018-09-14-arabic-grammar-md": () => import("./../../../posts/2018-09-14-arabic-grammar/2018-09-14-arabic-grammar.md" /* webpackChunkName: "component---posts-2018-09-14-arabic-grammar-2018-09-14-arabic-grammar-md" */),
  "component---posts-2018-11-28-tasgheer-2018-11-28-tasgheer-md": () => import("./../../../posts/2018-11-28-tasgheer/2018-11-28-tasgheer.md" /* webpackChunkName: "component---posts-2018-11-28-tasgheer-2018-11-28-tasgheer-md" */),
  "component---posts-2018-12-01-nasb-2018-12-01-nasb-md": () => import("./../../../posts/2018-12-01-nasb/2018-12-01-nasb.md" /* webpackChunkName: "component---posts-2018-12-01-nasb-2018-12-01-nasb-md" */),
  "component---posts-2018-12-23-tawabi-2018-12-23-tawabi-md": () => import("./../../../posts/2018-12-23-tawabi/2018-12-23-tawabi.md" /* webpackChunkName: "component---posts-2018-12-23-tawabi-2018-12-23-tawabi-md" */),
  "component---posts-2019-01-03-diptote-mamnu-min-as-sarf-2019-01-03-diptote-mamnu-min-as-sarf-md": () => import("./../../../posts/2019-01-03-diptote-mamnu-min-as-sarf/2019-01-03-diptote-mamnu-min-as-sarf.md" /* webpackChunkName: "component---posts-2019-01-03-diptote-mamnu-min-as-sarf-2019-01-03-diptote-mamnu-min-as-sarf-md" */),
  "component---posts-2019-01-12-adad-2019-01-12-adad-md": () => import("./../../../posts/2019-01-12-adad/2019-01-12-adad.md" /* webpackChunkName: "component---posts-2019-01-12-adad-2019-01-12-adad-md" */),
  "component---posts-2019-02-07-mudhari-2019-02-07-mudhari-md": () => import("./../../../posts/2019-02-07-mudhari/2019-02-07-mudhari.md" /* webpackChunkName: "component---posts-2019-02-07-mudhari-2019-02-07-mudhari-md" */),
  "component---posts-2019-02-11-mabni-2019-02-11-mabni-md": () => import("./../../../posts/2019-02-11-mabni/2019-02-11-mabni.md" /* webpackChunkName: "component---posts-2019-02-11-mabni-2019-02-11-mabni-md" */),
  "component---posts-2019-02-24-introduction-to-balagha-2019-02-24-introduction-to-balagha-md": () => import("./../../../posts/2019-02-24-introduction-to-balagha/2019-02-24-introduction-to-balagha.md" /* webpackChunkName: "component---posts-2019-02-24-introduction-to-balagha-2019-02-24-introduction-to-balagha-md" */),
  "component---src-pages-arabic-grammar-tutorials-in-english-js": () => import("./../../../src/pages/arabic-grammar-tutorials-in-english.js" /* webpackChunkName: "component---src-pages-arabic-grammar-tutorials-in-english-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

